.Activated_overlay__3ey1P {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: background-color;
}
.Activated_btn__BDRGy {
  cursor: zoom-out;
}
.Activated_content__2QBG5 {
  position: absolute;
  transition-property: transform;
  transform-origin: center center;
}

.Shared_trigger__3qcaj {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* reset styles */
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  font: inherit;
  color: inherit;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.Main_wrap__260iu {
  position: relative;
  display: inline-flex;
}
.Main_wrapHidden__iJWkw {
  visibility: hidden;
}
.Main_btn__3CiJa {
  cursor: zoom-in;
}

/*# sourceMappingURL=../styles.css.map */